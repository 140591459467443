<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green"
        indeterminate></v-progress-circular></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding pl-5">
                <div class="d-flex align-center">
                  <v-row>
                    <v-col cols="6">
                      <h5 class="font-weight-bold text-h5 text-typo mb-0">Manufacturing Order (MO)</h5>
                    </v-col>
                    <v-col cols="6"><v-spacer></v-spacer>
                      <v-btn elevation="0" :ripple="false" height="35" dark color="blue"
                        class="text-capitalize btn-ls btn-primary px-3" @click="back()" absolute
                        right>Back</v-btn></v-col>
                  </v-row>
                </div>
              </div>
              <v-form ref="frmFilter">
                <v-card-text>
                  <v-row>
                    <v-col cols="4" xs="12">
                      <label class="text-md text-typo font-weight-bolder ms-1 mt-2"> Warehouse : </label>
                      {{ blendPlan.companyWarehouseName || "N/A" }}
                    </v-col>
                    <v-col cols="4" xs="12">
                      <label class="text-md text-typo font-weight-bolder ms-1 mt-2"> Blend : </label>
                      {{ blendPlan.blendCodeName || "N/A" }}
                    </v-col>
                    <v-col cols="4" xs="12">
                      <label class="text-md text-typo font-weight-bolder ms-1 mt-2"> QTY : </label>
                      {{ blendPlan.productionQuantity || 0 }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" xs="12">
                      <label class="text-md text-typo ms-1" style="font-weight: 600 !important"> Production Date :
                      </label>
                      <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="date"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field :disabled="!isNew" v-model="datePicker" v-bind="attrs" v-on="on"
                            hide-details="auto"
                            class="input-style font-size-input text-light-input placeholder-light input-icon mt-2" dense
                            flat filled solo height="46" placeholder="Production Date" persistent-hint></v-text-field>
                        </template>
                        <v-date-picker v-model="datePicker" color="green lighten-1" no-title scrollable>
                          <!-- @change="changeDate()" -->
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
                          <v-btn text color="primary" @click="$refs.menu1.save(date)"> OK </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4" xs="12">
                      <label class="text-md text-typo ms-1" style="font-weight: 600 !important"> Lot Number : </label>
                      <v-text-field v-model="lot_number" outlined height="46"
                        class="input-style font-size-input text-light-input input-icon mt-2"
                        :readonly="this.blendPlan.mo_number ? true : false"></v-text-field>
                    </v-col>
                    <v-col cols="4" xs="12">
                      <v-spacer></v-spacer>
                      <v-btn v-if="mo_number == 'N/A'" elevation="0" :ripple="false" height="35" dark color="#38BD34"
                        class="text-capitalize btn-ls btn-primary px-3" @click="Submit()" absolute right>
                        Submit
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-card class="card-shadow border-radius-xl mt-5">
        <v-card-text class="card-padding">
          <v-row>
            <v-col offset="10"> </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table :headers="mainHeader" :items="blendDetails" :items-per-page="5" mobile-breakpoint="0"
                disable-pagination hide-default-header>
                <template v-slot:header="{ props }">
                  <th v-for="head in props.headers" style="text-align: start; padding-left: 20px" :key="head.idx">
                    {{ head.text }}
                  </th>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import RequisitionAPI from "../../Requisition/api";
import formatDate from "../../../../global-data/dateFormatter";

export default {
  async created() {
    this.capacity = this.blendPlan.productionQuantity;
    await this.getDetails();
  },
  data() {
    // console.log(this.$route.params.blendPlan);
    return {
      showDraftButton: true,
      color: ["green lighten-5", "purple lighten-5"],
      isNew: true,
      type: this.$route.params.type,
      mo_id: this.$route.params.id,
      blendPlan: this.$route.params.blendPlan,
      date: this.dateFormater(new Date(this.$route.params.blendPlan.productionDate)),
      datePicker: this.dateFormater(new Date(this.$route.params.blendPlan.productionDate)),
      lot_number: "",
      extendedBlendDetails: {
        id: 0,
        items: [],
      },
      overlay: false,
      menu1: false,
      currentItem: {},
      capacity: 0,
      mo_number: "",
      each_capacity: 0,
      blendDetails: [],
      height: window.innerHeight * 0.8,
      mainHeader: [
        {
          text: "Garden",
          value: "markName",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
        {
          text: "Grade",
          value: "gradeName",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",

          sortable: true,
        },
        {
          text: "Item",
          align: "left",
          sortable: true,
          value: "itemName",
        },
        {
          text: "Batch",
          align: "left",
          sortable: true,

          value: "batchNo",
        },

        {
          text: "Bags",
          align: "start",
          sortable: true,

          value: "bags",
        },
        {
          text: "QTY",
          align: "left",
          sortable: true,

          value: "quantity",
        },
      ],
    };
  },
  methods: {
    async init() {
      this.blendDetails = [];
      this.date = this.dateFormater(new Date(this.$route.params.blendPlan.productionDate));
    },
    async getDetails() {
      this.blendDetails = [];
      if (this.blendPlan.mo_number === null) {
        const data = await api.getBlendPlanDetails(this.blendPlan.id);

        let child = [];
        data.forEach((el) => {
          let obj = {
            markName: el.markName,
            gradeName: el.gradeName,
            itemName: el.itemName,
            batchNo: el.batchNo,
            quantity: el.totalNetKgs,
            bags: el.noOfPackages,
            generatedItemName: el.generatedItemName,
          };
          child.push(obj);
        });
        this.blendDetails = child;

        this.mo_number = "N/A";
        // this.capacity = this.blendDetails.capacity;
        this.lot_number = "";
      } else {
        console.log(this.$refs);

        const data = await api.getMoDetails(this.blendPlan.name);

        let child = [];
        data[0].child.forEach((el) => {
          let obj = {
            markName: el.markName,
            gradeName: el.gradeName,
            itemName: el.itemName,
            batchNo: el.batchNo,
            quantity: el.quantity,
            bags: el.bags,
            generatedItemName: el.generatedItemName,
          };
          child.push(obj);
        });
        this.blendDetails = child;

        this.mo_number = data[0].mo_number || "N/A";
        this.capacity = data[0].capacity;
        this.no_of_days = data[0].no_of_days;
        this.date = this.datePicker[0];
        this.lot_number = data[0].lot_number;
      }
    },

    async Submit() {
      // Validation Check For Lot Number
      if (!this.lot_number) {
        this.$swal({
          text: "Lot Number Is Required",
          type: "warning",
          icon: "warning",
        });
        return;
      }

      this.overlay = true;
      let data = await this.objCreate("SUBMIT");
      let res = await api
        .submitMo(data)
        .then((res) => {
          if (res.status == 201) {
            this.showSuccessAlert(res.message);
            this.mo_number = res.mo_number;
            this.$router.go(-1);
          }
          if (res.status == 401) this.showErrorAlert(res.message);
        })
        .catch((err) => {
          this.showErrorAlert(err);
        });
      this.overlay = false;
    },

    async objCreate(type) {
      let master = {
        date: new Date(this.datePicker),
        companyWarehouseName: this.blendPlan.companyWarehouseName,
        blendCodeName: this.blendPlan.blend_type,
        blend_plan: this.blendPlan.name,
        capacity: this.capacity,
        no_of_days: 1,
        status: type,
        lot_number: this.lot_number,
        source_number: this.blendPlan.source_number,
      };

      let child = [];
      this.blendDetails.forEach((el) => {
        let obj = {
          markName: el.markName,
          gradeName: el.gradeName,
          itemName: el.itemName,
          batchNo: el.batchNo,
          quantity: el.quantity,
          bags: el.bags,
          generatedItemName: el.generatedItemName,
        };
        child.push(obj);
      });
      master.moChild = child;
      let data = {
        master,
        blend_plan: this.blendPlan.name,
        type: type,
      };

      return data;
    },

    back() {
      this.$router.push({ name: "MO" });
    },
    changeDate() {
      this.datePicker = this.date;
    },

    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Something Went wrong.",
        text: message,
        type: "error",

        icon: "error",
        showConfirmButton: true,
      });
    },
    dateFormater(date) {
      return formatDate.formatDate(date);
    },
  },
};
</script>
